import {
  WRITE_PERMISSION,
  NONE_PERMISSION,
  MARKETPLACE_ALL_KEY
} from 'constants/account/permissions'
import * as Yup from 'yup'

export const createTeamValidationSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required('Required')
  })
}

export const canViewTeamSettings = ({
  isAccountOwnerOrManager,
  userLimit,
  hasSeatStripePlan
}) => {
  if (!isAccountOwnerOrManager) return false

  if (userLimit > 1) return true

  return !!hasSeatStripePlan
}

export const buildTeamMembers = (team, users) => {
  return users.filter(user => user.teams.map(t => t.id).includes(team.id))
}

export const buildInitials = ({ name, email }) => {
  if (!name && !email) return ''

  return (name || email)
    .split(' ')
    .map(word => word.charAt(0))
    .join('')
}

export const hasSellerAccountAccess = (marketplaceIds, teamPermissions) => {
  if (teamPermissions?.seller_marketplace_access) return true

  return marketplaceIds.every(marketplaceId => {
    const marketplace =
      teamPermissions?.marketplaces?.[marketplaceId.toString()]
    return marketplace?.[MARKETPLACE_ALL_KEY] === WRITE_PERMISSION
  })
}

export const toggleAccountAccess = (
  marketplaceIds,
  teamPermissions,
  hasAccess
) => {
  if (
    !teamPermissions ||
    !marketplaceIds ||
    marketplaceIds.length === 0 ||
    Object.keys(teamPermissions).length === 0
  ) {
    return teamPermissions
  }

  return {
    ...teamPermissions,
    marketplaces: marketplaceIds.reduce((acc, marketplaceId) => {
      return {
        ...acc,
        [marketplaceId]: {
          ...acc[marketplaceId],
          [MARKETPLACE_ALL_KEY]: hasAccess ? WRITE_PERMISSION : NONE_PERMISSION
        }
      }
    }, teamPermissions.marketplaces)
  }
}
