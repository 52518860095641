import {
  DEFAULT_APP_TYPE,
  DEFAULT_THEME,
  COBALT_THEME
} from '../constants/themes'

export const themeColors = (appType = 'default') =>
  appType === DEFAULT_APP_TYPE ? DEFAULT_THEME : COBALT_THEME

export const isAllowedAccess = (appAccessList, appType) => {
  return appAccessList.includes(appType)
}
