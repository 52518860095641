export const scrollToElement = (id, options) => {
  const element = document.getElementById(id)
  const { delay, offset } = options

  if (delay) {
    setTimeout(() => {
      handleScroll(element, offset)
    }, delay)
  } else {
    handleScroll(element, offset)
  }
}

const handleScroll = (element, offset) => {
  const topOffset = calculateTopOffset(element, offset)
  const isSmoothScrollSupported =
    'scrollBehavior' in document.documentElement.style

  if (isSmoothScrollSupported) {
    window.scrollTo({
      top: topOffset,
      behavior: 'smooth'
    })
  } else {
    window.scrollTo(0, topOffset)
  }
}

const calculateTopOffset = (element, offset) => {
  if (!element?.offsetParent?.offsetTop) return 0
  return element.offsetParent.offsetTop + element.offsetTop + (offset || 0)
}
