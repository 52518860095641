import { DEFAULT_APP_TYPE } from '../constants/themes'
import { someFeatureEnabled } from './features'
import { isAllowedAccess } from './themes'
import { hasAccess } from './permissions'

const hasMembershipType = ({ membershipType, membershipInfo }) => {
  // safety net - these don't always exist
  if (!membershipInfo || !membershipInfo[membershipType]) {
    return false
  }

  return membershipInfo[membershipType]
}

export const showSidebarItem = ({
  item,
  admin,
  appType,
  flagData,
  membershipInfo,
  permissions,
  isImpersonating
}) => {
  const {
    url,
    appAccessList,
    features,
    excludeFeatures,
    membershipType,
    permissionKey
  } = item

  if ((!admin || isImpersonating) && url === '/admin') return false

  const accessList = appAccessList || [DEFAULT_APP_TYPE]
  if (!isAllowedAccess(accessList, appType)) {
    return false
  }

  if (permissionKey?.length > 0) {
    const hasAnyAccess = permissionKey.some(key => hasAccess(permissions, key))
    if (!hasAnyAccess) return false
  }

  if (features && !someFeatureEnabled(features, flagData)) {
    return false
  }

  if (excludeFeatures && someFeatureEnabled(excludeFeatures, flagData)) {
    return false
  }

  if (
    membershipType &&
    !hasMembershipType({ membershipType, membershipInfo })
  ) {
    return false
  }

  return true
}
