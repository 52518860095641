export function escapeSpecialCharacters(str) {
  return str.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
}

export const textFromHtml = (htmlString, lineBreakersAsSpace = false) => {
  if (lineBreakersAsSpace) {
    htmlString = htmlString.replace(/<br[^>]*>/gi, ' ')
  }
  return htmlString.replace(/<(?:.|\n)*?>|[&]nbsp[;]/gim, '')
}

export function truncate(str, maxLength, append = null, lastChars = false) {
  if (!str) {
    return str
  }
  if (str.length > maxLength && lastChars) {
    const truncated = str.substring(0, maxLength)
    const last = str.slice(-3)
    return append ? `${truncated}${append}${last}` : truncated
  }
  if (str.length > maxLength) {
    const truncated = str.substring(0, maxLength)
    return append ? `${truncated}${append}` : truncated
  }
  return str
}

export function lowercase(str) {
  if (typeof str === 'string') {
    return str.toLowerCase()
  }

  return str
}

// checks if the string starts or ends with double or single quote.
export function trimQuotes(str) {
  if (typeof str === 'string') {
    return str.replace(/^(["'])(.*)\1$/, '$2')
  }

  return str
}

export const capitalize = string =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const snakeCaseToCapitalizedWords = string => {
  return (
    string
      ?.replace?.(/_/g, ' ')
      .split(' ')
      .map(word => capitalize(word))
      .join(' ') || ''
  )
}

export const toTitleCase = string => {
  const lowerCase = string.toLowerCase()

  return lowerCase
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const capitalizedWordToSnakeCase = string => {
  const match = string.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
  )
  if (match === null) return string
  return match.map(x => x.toLowerCase()).join('_')
}

export const randomString = length => {
  const validCharacters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  let str = ''

  for (let i = 0; i < length; i += 1) {
    str += validCharacters.charAt(
      Math.floor(Math.random() * validCharacters.length)
    )
  }

  return str
}

// Remove characters so the string can line-break
export const toLineBreakFriendly = dirty => {
  return dirty
    ?.replace(/~/g, ' ')
    .split(',')
    .map(part => part.trim())
    .join(', ')
    .split('|')
    .map(part => part.trim())
    .join(' | ')
    .split('/')
    .map(part => part.trim())
    .join(' / ')
}

export const wordContainsTerms = (word, terms) =>
  terms.some(term => word.includes(term) || term.includes(word))
